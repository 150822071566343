//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    reference: {
      type: String,
      default: "",
    },
    video: {
      type: Object,
      default: () => ({}),
    },
    hasToPreload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      url: process.env.api_url,
      showPlayButton: false,
      isLoaded: false,
      observer: null,
    };
  },
  mounted() {
    if (this.hasToPreload) {
      // Priority video loads immediately
      this.isLoaded = true;
      this.preloadVideo();
    } else {
      // Lazy load other videos when they enter viewport
      this.setupIntersectionObserver();
    }
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    setupIntersectionObserver() {
      this.observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.isLoaded = true;
              this.observer.unobserve(entry.target);
            }
          });
        },
        {
          rootMargin: "200px", // Start loading when 200px away from viewport
          threshold: 0.1,
        }
      );

      this.observer.observe(this.$el);
    },
    playVideo() {
      this.showPlayButton = false;
      const videoElement = this.$refs[this.reference || "videoElement"];
      if (videoElement) {
        videoElement.currentTime = 0;
        videoElement.play().catch((e) => console.error("Play error:", e));
      }
    },
    handleVideoEnded() {
      this.showPlayButton = true;
    },
    preloadVideo() {
      const videoElement = this.$refs[this.reference || "videoElement"];
      if (videoElement && this.hasToPreload) {
        videoElement.load();
      }
    },
  },
};
